import {
  InputField,
  InputFieldNew,
  InputLabel,
  Link,
  ThemedButtonNew,
  ErrorText,
  Heading1New,
  SecureLoginIconNew,
  WarningNotification,
} from "ccp-common-ui-components";
import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store/Store";
import { setChannel, sendOtc, unsetPreVerification } from "./SendOtcSlice";
import {
  clearOtcVerificationErrorMessage,
  SecureLoginInputs,
  verifyOtc,
} from "./OtcVerificationSlice";
import { setShowLoader } from "../loader/LoaderSlice";
import { continueRule } from "../continue/ContinueSlice";
import {
  dataLayerGenericInlineErrorWithArray,
  dataLayerSecureLoginPage,
} from "../../tracking/tracking";
import { validateOneTimeCode } from "../../common/validation/Validation";
import { useWithNav } from "../../utils/withNav";
import { RouteState } from "../route/RouteSlice";
import { ProfileRoute } from "../../common/constants";
import { isRouteMfaMobileExempt } from "../../common/commonFunctions";

function SecureLoginPage() {
  const dispatch = useDispatch();
  const withNav = useWithNav<SecureLoginInputs>();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<SecureLoginInputs>();

  let { otcVerificationErrorMessage } = useSelector(
    (state: RootState) => state.otcVerification
  );

  const {
    sentTo,
    maxRetryReached,
    channel,
    smsGatewayUnavailable,
  } = useSelector((state: RootState) => state.sendOtc);

  const { route } = useSelector((state: RootState) => state.route);

  const [
    isMaxRetriesNotificationOpen,
    setIsMaxRetriesNotificationOpen,
  ] = useState(maxRetryReached);

  useEffect(() => {
    maxRetryReached
      ? setIsMaxRetriesNotificationOpen(true)
      : setIsMaxRetriesNotificationOpen(false);
  }, [maxRetryReached]);

  useEffect(() => {
    if (smsGatewayUnavailable || isRouteMfaMobileExempt(route)) {
      switchToEmail();
    } else {
      switchToSms();
    }
    dispatch(setShowLoader(false));
    dispatch(unsetPreVerification());
  }, [dispatch]);

  const clientName = useSelector(
    (state: RootState) => state.channel.clientName
  );

  useEffect(() => {
    if (clientName) {
      dataLayerSecureLoginPage(clientName);
    }
  }, [clientName]);

  const onSubmit = (formData: SecureLoginInputs) => {
    dispatch(verifyOtc(withNav(formData)));
  };

  const onSendAgain = () => {
    dispatch(clearOtcVerificationErrorMessage());
    clearErrorMessage();
    dispatch(sendOtc(withNav()));
  };

  const hasError = (): boolean => {
    return !!errors?.oneTimeCode?.message || !!otcVerificationErrorMessage;
  };

  const getErrorMessage = (): string => {
    if (errors?.oneTimeCode?.message) {
      return errors.oneTimeCode.message;
    } else if (otcVerificationErrorMessage) {
      return otcVerificationErrorMessage;
    }
    return "";
  };

  const clearErrorMessage = () => {
    if (errors?.oneTimeCode) {
      errors.oneTimeCode.message = "";
    }
    if (otcVerificationErrorMessage) {
      otcVerificationErrorMessage = "";
    }
  };

  const [switchLinkText, setSwitchLinkText] = useState("");

  function switchToEmail() {
    dispatch(setChannel("email"));
    setSwitchLinkText("send code to my mobile");
  }

  function switchToSms() {
    dispatch(setChannel("sms"));
    setSwitchLinkText("email code");
  }

  function switchChannel() {
    if (channel === "sms") {
      switchToEmail();
    } else {
      switchToSms();
    }
  }

  const otcFieldRef = useRef<HTMLInputElement | null>(null);
  useEffect(() => {
    if (hasError()) {
      otcFieldRef.current?.focus();
    }
  }, [hasError]);

  useEffect(() => {
    if (hasError()) {
      let errorMessage: string[] = [getErrorMessage()];
      dataLayerGenericInlineErrorWithArray(errorMessage);
    }
  }, [hasError]);
  const registerOptions = register("oneTimeCode", {
    validate: validateOneTimeCode,
    onChange: () => {
      if (otcVerificationErrorMessage) {
        dispatch(clearOtcVerificationErrorMessage());
      }
    },
  });
  const newOtcInputField = (
    <InputFieldNew
      id="oneTimeCode"
      data-testid="oneTimeCode"
      type="tel"
      autoComplete="off"
      hasError={hasError()}
      maxLength={6}
      aria-describedby="one-time-code-error"
      aria-invalid={hasError()}
      aria-required="true"
      {...registerOptions}
      ref={(e) => {
        otcFieldRef.current = e;
        registerOptions.ref(e);
      }}
    />
  );

  return (
    <MfaDiv>
      <form
        id="secure-login-form"
        onSubmit={handleSubmit(onSubmit)}
        data-testid="secure-login-form"
        noValidate
      >
        <StyledHeading1New>
          {" "}
          <UnlockIconNew />
          Secure log in
        </StyledHeading1New>
        <StyledP>
          Enter the one-time code we sent to{" "}
          <MobileNumberText>{sentTo}</MobileNumberText>
        </StyledP>
        <StyledInputLabel htmlFor="oneTimeCode">One-time code</StyledInputLabel>
        {newOtcInputField}
        {hasError() && (
          <StyledErrorText
            id="one-time-code-error"
            data-testid="one-time-code-error"
          >
            {getErrorMessage()}
          </StyledErrorText>
        )}

        <StyledDiv data-testid="send-again-text">
          <Link
            id="send-again"
            data-testid="send-again"
            primary={true}
            href="/"
            onClick={(e) => {
              e.preventDefault();
              onSendAgain();
            }}
          >
            {channel === "sms"
              ? "Resend code to my mobile"
              : "Re-email me the code"}
          </Link>{" "}
          {smsGatewayUnavailable || isRouteMfaMobileExempt(route) ? (
            <></>
          ) : (
            <>
              or{" "}
              <Link
                id="switch-channel"
                data-testid="switch-channel"
                primary={true}
                href="/"
                onClick={(e) => {
                  e.preventDefault();
                  switchChannel();
                  onSendAgain();
                }}
              >
                {switchLinkText}
              </Link>
            </>
          )}
        </StyledDiv>
        <WarningNotification
          data-testid="max-retry-reached-error"
          text="You've reached the maximum limit for one-time codes sent. Wait 5 minutes to request a new code."
          isOpen={isMaxRetriesNotificationOpen}
          closeHandler={() => {
            setIsMaxRetriesNotificationOpen(false);
          }}
        />

        <StyledCenteredDiv>
          <StyledButtonNew type="submit">Continue</StyledButtonNew>
        </StyledCenteredDiv>
        <StyledCenteredDiv>
          <StyledLink
            id="back-login"
            data-testid="back-login"
            onClick={(e) => {
              e.preventDefault();
              dispatch(continueRule());
            }}
          >
            Back
          </StyledLink>
        </StyledCenteredDiv>
      </form>
    </MfaDiv>
  );
}

export default SecureLoginPage;

const StyledLink = styled(Link)`
  margin-top: 16px;
  font-weight: 600;
  font-size: 18px;
  color: #212121;
`;

const StyledP = styled.p`
  margin-top: 8px;
  margin-bottom: 24px;
`;

const StyledInputLabel = styled(InputLabel)`
  font-size: 16px;
`;

const StyledDiv = styled.div`
  margin-top: 8px;
  margin-bottom: 24px;
`;

const StyledCenteredDiv = styled.div`
  text-align: center;
  margin-top: 20px;
`;

const StyledButtonNew = styled(ThemedButtonNew)`
  width: 100%;
`;

const StyledErrorText = styled(ErrorText)`
  margin-bottom: 20px;
`;

const MobileNumberText = styled.span`
  font-weight: bold;
`;

const OtcInputField = styled(InputField)`
  width: 105px;
`;

const MfaDiv = styled.div`
  padding-bottom: 35px;
`;

const UnlockIcon = styled.img`
  margin-right: 8px;
`;

const UnlockIconNew = styled(SecureLoginIconNew)`
  margin-right: 8px;
`;

const MaxRetryErrorDiv = styled.div`
  display: flex;
  margin-bottom: 20px;
  color: #6e6e6e;
`;

const MaxRetryErrorMessage = styled.div`
  padding-left: 8px;
`;

const StyledHeading1New = styled(Heading1New)`
  margin-bottom: 8px;
`;
